/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { HeaderComponent } from './common/header/header.component'
import { CampaignsComponent } from './components/campaigns/campaigns.component'
import { NavBarComponent } from './common/nav-bar/nav-bar.component'
// import { FooterComponent } from './common/footer/footer.component'
import { NoCampaignComponent } from './components/no-campaign/no-campaign.component'
import { RightNavbarComponent } from './components/right-navbar/right-navbar.component'
import { RightNavbarQuicklinkComponent } from './components/right-navbar-quicklink/right-navbar-quicklink.component'
import { ProposalComponent } from './components/proposal/proposal.component'
import { ScheduleComponent } from './components/schedule/schedule.component'



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [HeaderComponent, CommonModule, CampaignsComponent, NavBarComponent, NoCampaignComponent, RightNavbarComponent, RightNavbarQuicklinkComponent, RouterModule, ProposalComponent, ScheduleComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})

export class AppComponent {
  title = 'ClientProposalApp'

  constructor (){}
}
