<div class="border border-solid rounded-lg border-neutral-200 p-[12px] flex justify-start gap-[8px] flex-col">
    <p class="text-small font-inter font-semibold text-black">Quick link</p>
    <p class="text-neutral-400 text-small font-inter font-semibold">Campaign summary</p>
    <button
    class="btn border hover:bg-primary-400 hover:text-white-50 h-[40px] text-primary-400 bg-white-50 border-primary-400 flex justify-center">
    Preview 
    <span class="w-[20px] h-[20px] ml-[6px]">
        <img src="../../../assets/icons/blue-play-icon.svg" alt="play" />
    </span>
    </button>
</div>