import { Component } from '@angular/core'

@Component({
  selector: 'app-right-navbar-quicklink',
  standalone: true,
  imports: [],
  templateUrl: './right-navbar-quicklink.component.html',
  styleUrl: './right-navbar-quicklink.component.css'
})
export class RightNavbarQuicklinkComponent {

}
