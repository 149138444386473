<div class="text-center block relative w-full bg-white-50 p-[32px] shadow-md">
    <form [formGroup]="createCampaignForm" (keydown.enter)="$event.preventDefault()" (keydown.shift.enter)="$event.preventDefault()">
        <div class="flex flex-col gap-[24px]">
            <div class="flex flex-col gap-[8px] text-left">
                <div class="flex flex-row justify-between w-full">
                    <h6 id="CreateCampaignTitle" class="font-farro font-bold text-para-lg text-grey-300">{{modalConfig.heading}}</h6>
                    <button class="cursor-pointer" (click)="onClose()" class="hover:bg-neutral-50">
                        <img src="../../../../../assets/icons/close-icon.svg"
                            alt="close"
                            class="w-[10px] h-[10px]"
                            width={10}
                            height={10}
                        />
                    </button>
                </div>
                <p class="font-inter font-normal text-black text-small">{{modalConfig.desc}}</p>
            </div>
            <div class="col-span-full">
                <label
                    htmlFor="username"
                    class="block text-small font-semibold font-inter text-left text-neutral-600">
                    Campaign name
                </label>
                <div class="mt-2">
                    <div class="flex rounded-md shadow-sm ring-1 ring-inset bg-neutral-50 border border-neutral-400">
                        <input formControlName="campaignName"
                            id="campaignName"
                            name="campaignName"
                            type="text"
                            placeholder="Enter a name for your campaign"
                            autoComplete="First name"
                            class="block flex-1 bg-white-50 py-[10px] pl-[12px] text-grey-300 placeholder:text-grey-300 text-small font-inter font-normal rounded-md"
                        />
                    </div>
                </div>
                <div>
                    <p class="text-x-small font-inter font-semibold text-red-600 px-[8px] py-[2px] rounded-lg">{{errorMessage}}</p>
                </div>
            </div>
        </div>
    </form>
    <div class="w-full flex justify-end mt-[8px] gap-[24px] pt-[16px]">
        <!-- <button
            class="btn text-primary-400 hover:text-white-50 hover:bg-primary-400 h-[40px]">
            Cancel
        </button> -->
        <button [disabled]="!createCampaignForm.valid" (click)="onSubmit()"
            class="btn border bg-primary-400 text-white-50 h-[40px] hover:text-primary-400 hover:bg-white-50 hover:border-primary-400">
            {{modalConfig.action}}
        </button>
    </div>
</div>