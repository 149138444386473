import { Component, Input, OnInit } from '@angular/core';
import { FirstCampaignComponent } from "../first-campaign/first-campaign.component";
import { SelectCourseComponent } from "../select-course/select-course.component";
import { GoalComponent } from "../goal/goal.component";
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RightNavbarQuicklinkComponent } from "../right-navbar-quicklink/right-navbar-quicklink.component";
import { RightNavbarComponent } from "../right-navbar/right-navbar.component";
import { ProposalComponent } from "../proposal/proposal.component";
import { ScheduleComponent } from "../schedule/schedule.component";
import { ApiService } from '../../services/api.service';
import { CampaignService } from '../../services/campaign.service';
import { scheduled } from 'rxjs';
import { SharedData } from '../../models/CreateCampaign.models';

@Component({
  selector: 'app-campaign-form',
  standalone: true,
  imports: [SelectCourseComponent, GoalComponent, CommonModule, RouterModule, RightNavbarQuicklinkComponent, RightNavbarComponent, ProposalComponent, ScheduleComponent, FirstCampaignComponent],
  templateUrl: './campaign-form.component.html',
  styleUrl: './campaign-form.component.css'
})
export class CampaignFormComponent implements OnInit {
  campaignCreated: boolean = true;
  currentPagePointer: number = 1
  //new code
  btnDisable: boolean = true; // Initial state for buttons
  isNextDisable: boolean = true;//Initially set the next button to be disabled
  formData: any = {};  // To store form data from each page
  campaignId: string | null = localStorage.getItem('CampaignId') ? localStorage.getItem('CampaignId') : ''
  pageContent: SharedData ={}

  constructor(private apiService: ApiService, private campaignService: CampaignService) { }

  ngOnInit(): void {
    //InitalForm condition Check
    this.isNextDisable = true
  }

  // Update button state based on the form's validity
  onFormValidityChange(data: any) {

    console.log(data.isValid, "isValid")
    console.log(data.formValue, "formValue")

    if (data.isValid) {
      switch (this.currentPagePointer) {
        case 1: {
          this.isNextDisable = !data.isValid; //false
          // Store form data by page
          // this.formData[this.currentPagePointer] = {
          //   formValue: data.formValue
          // } 
          this.campaignService.updateData('goal', data.formValue)
          break;
        }
        case 2: {
          this.isNextDisable = !data.isValid;
          // Store form data by page
          // this.formData[this.currentPagePointer] = {
          //   formValue: data.formValue
          // }
          this.campaignService.updateData('selectCourse', data.formValue)
          break;
        }
        // case 3:{
        //   this.isNextDisable = !data.isValid
        //   // Store form data by page
        //   this.formData[this.currentPagePointer] = {
        //     formValue: data.formValue
        //   }
        //   break;
        // }
        case 4: {
          this.isNextDisable = !data.isValid
          // Store form data by page
          // this.formData[this.currentPagePointer] = {
          //   formValue: data.formValue
          // }
          this.campaignService.updateData('proposal', data.formValue)
          break;
        }
        case 5: {
          this.isNextDisable = !data.isValid
          // Store form data by page
          // this.formData[this.currentPagePointer] = {
          //   formValue: data.formValue
          // }
          this.campaignService.updateData('schedule', data.formValue)
          break;
        }
      }
    }
  }

  nextPage() {
    if (this.currentPagePointer < 3) {
      this.currentPagePointer++;
    }
  }

  previousPage() {
    if (this.currentPagePointer > 1) {
      this.currentPagePointer--;
    }
  }

  onNextClick(cp: any) {
    if (this.currentPagePointer <= 5) {
      this.currentPagePointer = this.currentPagePointer + 1
      localStorage.setItem('currentPagePointer', (this.currentPagePointer).toString())
      this.isNextDisable = true

      if(this.currentPagePointer === 3){
        this.isNextDisable = false
      }

      const data = this.campaignService.getData()
      
      Object.keys(data).forEach((key) => {        
        const keys = Object.keys(data) as (keyof SharedData)[];

        for (let i = 0; i < this.currentPagePointer -1; i++) {
          const key = keys[i];
          this.pageContent[key] = data[key];
        }
      });

      this.apiService.updateCampaign({ campaignId: this.campaignId, pageContent: this.pageContent }).subscribe((res: any) => {        
        console.log(res, "response for Update")
      })
    }
  }

  onPrevClick(cp: any) {
    if (this.currentPagePointer > 1) {
      this.currentPagePointer = this.currentPagePointer - 1
      localStorage.setItem('currentPagePointer', (this.currentPagePointer).toString())
    }
  }
}
