<nav class="h-full w-[310px] flex flex-col items-start p-4">
    <ul class="flex flex-col space-y-4">
      <li>
        <a routerLink="/home" title="Home" class="text-black hover:text-yellow-400 px-[12px] py-[10px]">
          <i class="fa-solid fa-home fa-lg w-[20px] h-[20px]" ></i>
          <span class="pl-4">Home</span>
        </a>
      </li>
      <li>
        <a routerLink="/about" title="About" class="text-black hover:text-yellow-400 px-[12px] py-[10px]">
          <i class="fas fa-info-circle fa-lg w-[20px] h-[20px]"></i>
          <span class="pl-4">About</span>
        </a>
      </li>
      <li>
        <a routerLink="/services" title="Services" class="text-black hover:text-yellow-400 px-[12px] py-[10px]">
          <i class="fas fa-cogs fa-lg w-[20px] h-[20px]"></i>
          <span class="pl-4">Services</span>
        </a>
      </li>
      <li>
        <a routerLink="/contact" title="Contact" class="text-black hover:text-yellow-400 px-[12px] py-[10px]">
          <i class="fas fa-envelope fa-lg w-[20px] h-[20px]"></i>
          <span class="pl-4">Contact</span>
        </a>
      </li>
    </ul>
  </nav>  
 