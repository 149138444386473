<div class="text-center block relative w-full bg-white-50 p-[32px] shadow-md">
    <form [formGroup]="goalForm">
        <div class="flex flex-col gap-[24px]">
            <div class="flex flex-col gap-[8px] text-left">
                <div class="flex flex-row justify-between w-full">
                    <h6 class="font-farro font-bold text-para-lg text-grey-300">Campaign goal</h6>
                </div>
                <p class="font-inter font-normal text-black text-small">Select the type of proposal you'd like to send based on your campaign objectives.</p>
            </div>
            <div class="col-span-full flex gap-[16px]">
                <div class="rounded-lg border border-solid border-neutral-400 flex items-start gap-[12px] w-1/2">
                    <label class="flex items-center space-x-2 cursor-pointer w-full p-[16px] h-full gap-[12px]  peer-checked:bg-primary-0 peer-checked:border-primary-500">
                        <input  formControlName="goal" type="radio" name="goal" value="0" class="form-radio h-[20px] w-[20px] text-blue-600 border-gray-300 focus:ring-blue-500 pt-[5px] peer">                    
                        <p class="flex flex-col items-start ">
                            <span class="font-inter text-para text-grey-300 font-semibold">A FastLane course</span>
                            <span class="font-inter text-small text-grey-300 font-medium">(Max 1 course)</span>
                        </p>
                    </label>
                </div>
                <div class="rounded-lg border border-solid border-neutral-400 p-[16px] flex items-start gap-[12px] w-1/2">
                    <label class="flex items-center space-x-2 pt-[5px]">
                        <input formControlName="goal"  type="radio" name="goal" value="1" class="form-radio h-[20px] w-[20px] text-blue-600 border-gray-300 focus:ring-blue-500 cursor-pointer">
                    </label>
                        <p class="flex flex-col items-start">
                            <span class="font-inter text-para text-grey-300 font-semibold">Multiple FastLane courses</span>
                            <span class="font-inter text-small text-grey-300 font-medium">(Max 5 courses)</span>
                        </p>
                </div>
            </div>
        </div>
    </form>
</div>
