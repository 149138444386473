<div class="flex flex-col gap-[16px] bg-white-50 p-[24px] shadow-md">
    <div class="flex flex-col gap-[4px]">
        <h6 class="font-farro font-bold text-para-lg text-grey-300">Proposal</h6>
        <p class="font-inter font-normal text-small text-grey-300">Provide the key elements for your proposal to make it stand out</p>
    </div>
    <form class="flex flex-col gap-[4px]" [formGroup]="proposalForm">
        <p class="font-semibold font-inter text-small text-neutral-600">Proposal message</p>
        <div class="inline-flex border border-neutral-400 rounded-md w-full">
            <select
                formControlName="proposalType"
                id="country"
                name="country"
                autoComplete="off"
                class="py-[10px] pl-[12px] text-small font-inter font-normal rounded-md flex w-full bg-neutral-50">
                <option>Fee waiver</option>
                <option>Fee waiver a</option>
                <option>Fee waiver qa</option>
            </select>
        </div>
        <p class="font-normal text-small">Describe what you're offering in this proposal.</p>
    <div class="flex flex-col gap-[4px]">
        <textarea id="text-input" class="py-[10px] px-[12px] border border-neutral-400 rounded-md w-full text-grey-500 text-small" formControlName="description" rows="2" placeholder="As part of our commitment to supporting your academic journey, we are pleased to provide a 5% fee waiver for this course" minlength="1" [class.error]="proposalForm.get('description')?.invalid && proposalForm.get('description')?.touched"></textarea>
        <p *ngIf="proposalForm.get('description')?.invalid && proposalForm.get('description')?.touched" >"Text length should not exceed more than 250"
        </p>
    </div>
    <p class="font-normal text-small">This will be the exclusive offer from you to the student.</p>

    <div class="py-[16px] border-y border-solid border-neutral-200">
        <div class="bg-neutral-50 py-[40px] px-[24px] border border-dashed border-primary-400 w-full flex items-center flex-col rounded-md gap-[8px]">
            <p class="font-inter font-semibold text-black text-para-lg mb-[4px]">Exclusive image/video<span class="text-small font-normal ml-[4px]">(optional)</span></p>
            <p class="font-inter font-normal text-black text-small mb-[4px]">Upload an image to personalize your proposal</p>
            <p class="font-inter font-normal text-x-small text-black">An image/video specific to course would be great. Eg: A testimonial video from a student, professor.</p>
            <!-- <button
            class="font-semibold font-inter text-small text-primary-400 hover:underline py-[8px]">
                Create a campaign
            </button> -->
            <label for="file" class="font-semibold font-inter text-small text-primary-400 hover:underline py-[8px]">Click to upload</label>
            <input id="file" type="file" formControlName="imageOrVideoFile" (change)="onFileSelected($event)" accept="image/*,video/*" style="display: none;"/>
            <p class="text-neutral-400 text-x-small font-normal">Image: JPG/PNG, max size 2MB and Video: MP4, max size XX MB</p>
            <div class="flex items-center py-[16px]" *ngIf="selectedFile">
                <div class="w-[32px] h-[32px] bg-neutral-100 flex justify-center items-center mr-[12px] rounded-full">
                    <img class="w-[16px] h-[16px]" src="../../../assets/icons/grey-doc-icon.svg" alt="doc" />
                </div>
                <div class="flex gap-[8px] flex-col w-[288px]">
                    <p class="font-medium text-para text-neutral-600">{{selectedFile?.name}}</p>
                    <div class="flex w-full h-4 bg-gray-200 rounded-full overflow-hidden">
                        <p class="flex flex-col justify-center rounded-full overflow-hidden bg-primary-400 transition duration-500"
                           [ngStyle]="{ 'width': progress + '%' }">
                        </p>
                    </div>
                    <div>
                        <p class="text-small text-neutral-400 gap-[5px]" [ngClass]="{ 'flex': progressStatus === 'success', 'hidden': progressStatus !== 'success'}">
                            <span>{{selectedFileSize}}</span>
                            <span>•</span>
                            <span>100%</span>
                            <span>uploaded</span>
                        </p>
                        <p class="text-small text-negative-default"
                        [ngClass]="{ 'hidden': progressStatus === 'success'}"> 
                            Oops! Upload failed
                        </p>
                    </div>
                </div>
                <div class="">
                    <p class="w-[32px] h-[32px] bg-negative-light rounded-full cursor-pointer flex justify-center items-center ml-[24px]" (click)="removeFile()" (keydown)="onImageKeyDown($event)">
                        <img class="w-[20px] h-[20px]" src="../../../assets/icons/red-recyclebin-icon.svg" alt="recycle" />
                    </p>
                    <p class="w-[32px] h-[32px] bg-primary-0 rounded-full cursor-pointer flex justify-center items-center ml-[24px]">
                        <img class="w-[20px] h-[20px]" src="../../../assets/icons/blue-close-icon.svg" alt="close"/>
                    </p>
                    <p class="w-[32px] h-[32px] bg-neutral-50 rounded-full cursor-pointer flex justify-center items-center ml-[24px]">
                        <img class="w-[20px] h-[20px]" src="../../../assets/icons/grey-recycle-icon.svg" alt="recycle"/>
                    </p>
                </div>
            </div>
        </div>
    </div>
        <p class="font-semibold font-inter text-small text-neutral-600">Proposal validity</p>
        <div class="inline-flex border border-neutral-400 rounded-md w-full">
            <input type="date" id="date" name="date" formControlName="proposalValidity" [min]="currentDate">
        </div>
    </form>
</div>