

<div class="flex flex-col gap-[32px]">
    <!-- IF campaignCreated, this place has to display stepper/Progress bar -->
     <!-- <app-progress-bar></app-progress-bar> -->
    <ng-container *ngIf="!campaignCreated">
        <div class="w-full">
            <div class=" flex justify-between w-full">
                <div class="flex flex-col gap-[8px]">
                    <h6 class="font-farro font-bold text-para-lg text-grey-300">Campaigns</h6>
                    <p class="font-inter font-normal text-black text-small">Create personalized proposals to students</p>
                </div>
                <div class="flex flex-row gap-[16px] items-center">
                    <p class="font-inter font-normal text-black text-small">Need help?</p>
                    <p class="font-inter font-semibold text-primary-400 text-small">
                        <a href="#" class="flex gap-[8px] hover:underline">
                            Watch video
                            <span class="w-[20px] h-[20px]">
                                <img src="../../../assets/icons/Icon-right.svg" alt="play icon" />
                            </span>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="flex p-[40px] bg-white-50 shadow-custom-sm gap-[32px] flex-col">
        <!-- Check with Richy -->
        <!-- <app-progress-bar></app-progress-bar>-->
         
        <!-- Check with Richy -->
        <!-- Modal overlay and content -->
        <div *ngIf="isModalOpen" class="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
            <div class="relative bg-white-50 shadow-lg rounded-lg p-8 z-10">
                <app-create-campaign (close)="closeModal($event)" (campaignNameEvent)="CampaignCreationStarted($event)"></app-create-campaign>
            </div>
        </div>
    
        <!-- Disable content in the background if modal is open -->
        <div [class.pointer-events-none]="isModalOpen" [class.opacity-50]="isModalOpen">
        <div *ngIf="!campaignCreated">
           
            <div class="bg-neutral-50 p-[40px] border border-dashed border-primary-400 w-full flex items-center flex-col rounded-md">
                <p class="font-inter font-semibold text-black text-para mb-[4px]">No campaigns live</p>
                <p class="font-inter font-normal text-black text-para mb-[4px]">This is a placeholder text for description</p>
                <button (click)="openModal()"
                class="btn border bg-primary-400 text-white-50 h-[40px] mt-[16px] hover:text-primary-400 hover:bg-white-50 hover:border-primary-400 flex px-[20px] py-[10px] group">
                <span class="w-[20px] h-[20px] mr-[6px]">
                    <img src="../../../assets/icons/white-plus-icon.svg" alt="plus" class="group-hover:hidden"/>
                    <img src="../../../assets/icons/blue-plus-icon.svg" alt="plus" class="group-hover:flex hidden"/>
                </span>
                    Create a campaign
                </button>
        </div>
    
        <div class="flex gap-[24px] pt-[32px]">
            <div class="flex items-start gap-[12px] flex-col w-1/3">
                <span class="p-[8px] bg-primary-200 rounded-tl-[24px] rounded-br-[24px] w-[48px] h-[48px]">
                    <img src="../../../assets/icons/blue-star-icon.svg" alt="star icon" class="w-[32px] h-[32px]"/>
                </span>
                <div class="flex gap-[4px] flex-col">
                    <p class="font-inter font-semibold text-para-lg text-black pr-[16px]">Easy to use</p>
                    <p class="text-black text-small font-normal">Set up and manage campaigns easily; no technical skills needed.</p>
                </div>
            </div>
            <div class="flex items-start gap-[12px] flex-col w-1/3">
                <span class="p-[8px] bg-primary-200 rounded-tl-[24px] rounded-br-[24px] w-[48px] h-[48px]">
                    <img src="../../../assets/icons/blue-arrow-icon.svg" alt="star icon" class="w-[32px] h-[32px]"/>
                </span>
                <div class="flex gap-[4px] flex-col">
                    <p class="font-inter font-semibold text-para-lg text-black">Automated and Scalable 
                        Campaigns</p>
                    <p class="text-black text-small font-normal">Easily create and automate campaigns to reach students at scale without sacrificing personalization.</p>
                </div>
            </div>
            <div class="flex items-start gap-[12px] flex-col w-1/3">
                <span class="p-[8px] bg-primary-200 rounded-tl-[24px] rounded-br-[24px] w-[48px] h-[48px]">
                    <img src="../../../assets/icons/blue-letter-icon.svg" alt="star icon" class="w-[32px] h-[32px]"/>
                </span>
                <div class="flex gap-[4px] flex-col">
                    <p class="font-inter font-semibold text-para-lg text-black">Tailored, Data-Driven Proposals</p>
                    <p class="text-black text-small font-normal">Send the right message to the right student, improving engagement and conversion rates.</p>
                </div>
            </div>
        </div>
    </div>
    
    </div>
    
        <!-- Check with Richy -->
         <ng-container *ngIf="campaignCreated">
         <!-- <ng-container [ngSwitch]="currentPagePointer"> -->
    
            <!-- Goal Component -->
         <!-- <ng-container *ngSwitchCase="1">
            <div>
                <app-goal></app-goal>
             </div>
         </ng-container> -->
    
            <!-- Select Course Component -->
         <!-- <ng-container *ngSwitchCase="2">
            <div>
                <app-select-course></app-select-course>
            </div>
         </ng-container> -->
    
            <!-- First Campaign Component -->
        <!-- <ng-container *ngSwitchCase="3">
            <div>
                <app-first-campaign></app-first-campaign>
            </div>
        </ng-container> -->
    
         <!-- </ng-container> -->

         <app-campaign-form></app-campaign-form>
         </ng-container>
    </div>
</div>
