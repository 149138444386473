import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { CampaignService } from '../../services/campaign.service';

@Component({
  selector: 'app-select-course',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule,CommonModule],
  templateUrl: './select-course.component.html',
  styleUrl: './select-course.component.css'
})
export class SelectCourseComponent implements OnInit{

  selectCourseForm!: FormGroup
  // @Output() formComplete = new EventEmitter<boolean>();
  @Output() formComplete = new EventEmitter<{ isValid: boolean, formValue: any }>(); 
  campaignId : string | null = localStorage.getItem('CampaignId') ? localStorage.getItem('CampaignId') : ''

  btnDisable: boolean = true
  isMultiSelect: boolean = true
  isCourseDropdownOpen: boolean = false




  // Mock dropdown data
  studyLevels: any = ['PG','UG'];
  subjectsList: any =['Engineering', 'Science', 'Arts'];
  courseList: any = [];
  intakeList: any = [];
  campusList: any = []; 

  //Selected items
  subjectSelected: any = localStorage.getItem('subject')
  courseSelected: any = localStorage.getItem('course')
  intakeSelected: any = localStorage.getItem('intake')
  campusSelected: any = localStorage.getItem('campus')

  // Track first selection for each dropdown
  subjectSelectedFirstTime = false;
  courseSelectedFirstTime = false;
  intakeSelectedFirstTime = false;
  campusSelectedFirstTime = false;

  constructor(private fb: FormBuilder, private apiService: ApiService, private campaignService: CampaignService){}

  ngOnInit(): void {
    // const {
    //   '1': goal,
    //   '2': course,
    //   '3': audience,
    //   '4': proposal,
    // } = this.campaignService.getData();

    console.log(this.campaignService.getData())

    this.isMultiSelect = Number(localStorage.getItem('goal')) === 2 ? true : false
  
    //Initialise the Form
    this.selectCourseForm = this.fb.group({
      studyLevel: ['', Validators.required],
      subject : ['',Validators.required],
      course: ['',Validators.required],
      intake: ['',Validators.required],
      campus:['', Validators.required]
    })


    //Inital Form Conditons 
    this.selectCourseForm.get('studyLevel')?.setValue('PG')
    this.selectCourseForm.get('studyLevel')?.disable()
    if(this.subjectSelected){
      this.selectCourseForm.get('course')?.enable()
      this.selectCourseForm.get('intake')?.enable()
      this.selectCourseForm.get('campus')?.enable()  

      this.courseList = ['Master of Professional Engineering', 'Bachelor of Science', 'Bachelor of Arts']
      this.intakeList = ['Jan 2025', 'Sep 2025']
      this.campusList = ['campus1', 'campus2']
    }
    else{
      this.selectCourseForm.get('course')?.disable()
      this.selectCourseForm.get('intake')?.disable()
      this.selectCourseForm.get('campus')?.disable()  
    }


    //subject - Listen for value changes
    // this.selectCourseForm.get('studyLevel')?.valueChanges.subscribe((res)=>{
    //   console.log(res, "studyLevel Res")
    //   if(res){
    //     this.subjects= ['Engineering', 'Science', 'Arts']
    //   }
    // })

    //1. Do API call to get the Subject List with University (studyLevel - default 'PG')
    
    //2. Do API call to get Course List with StudyLevel + University + Subject
    this.selectCourseForm.get('subject')?.valueChanges.subscribe((res:any)=>{
      console.log(res, "valuChange in course")
      if(res){ 
        if(!this.subjectSelectedFirstTime){
          this.subjectSelectedFirstTime = true
          // this.enableCourseIntakeCampus();
          this.selectCourseForm.get('course')?.enable();
          this.selectCourseForm.get('intake')?.enable();
          this.selectCourseForm.get('campus')?.enable()

          this.courseList = ['Master of Professional Engineering', 'Bachelor of Science', 'Bachelor of Arts']
          this.intakeList = ['Jan 2025', 'Sep 2025']
          this.campusList = ['campus1', 'campus2']
        }
        else{
          // this.resetCourseIntakeCampus();
          // this.selectCourseForm.get('course')?.setValue('');
          // this.selectCourseForm.get('intake')?.setValue('');
          // this.selectCourseForm.get('campus')?.setValue('')
        }
      }
    }) 

    //3. Do API call to get Intake List with StudyLevel + University + Subject + Course 
    this.selectCourseForm.get('course')?.valueChanges?.subscribe((res: any)=>{
      if(res){
        if(!this.courseSelectedFirstTime){
          this.courseSelectedFirstTime = true
          // this.enableIntakeCampus();
        }
        else{
          // this.resetIntakeCampus();
          // this.selectCourseForm.get('campus')?.setValue('');
          // this.selectCourseForm.get('intake')?.setValue('');
        }
      }
    })

    //4. Do API call to get Campus List with StudyLevel + University + Subject + Course + Intake
    this.selectCourseForm.get('intake')?.valueChanges?.subscribe((res: any)=>{
      if(res){
        if (!this.intakeSelectedFirstTime) {
        this.intakeSelectedFirstTime = true;
        // this.enableCampus();
      } else {
        // this.resetCourseCampus();
        // this.selectCourseForm.get('course')?.setValue('');
        // this.selectCourseForm.get('campus')?.setValue('');
      }
      }
    })

    //5. To save campus Value in LocalStorage 
    this.selectCourseForm.get('campus')?.valueChanges?.subscribe((res:any)=>{
      if(res)
      {
        if (!this.campusSelectedFirstTime) {
          this.campusSelectedFirstTime = true;
        } else {
          // this.resetCourseIntake();
          // this.selectCourseForm.get('course')?.setValue('');
          // this.selectCourseForm.get('intake')?.setValue('');
        }
      }
    })

    //SelectCourseForm - Listen for value changes
    this.selectCourseForm.valueChanges.subscribe((res)=>{
      if(this.selectCourseForm.valid){
        const isValid = this.selectCourseForm.valid;
        const formValue = this.selectCourseForm.value;
        this.formComplete.emit({ isValid, formValue }); // Emit both validity and form values
      }
      // this.formComplete.emit(this.selectCourseForm.valid)
    })

    //Not Patching properly to form,check later
    // this.apiService.getCampaignDetails({campaignId : this.campaignId}).subscribe((res:any)=>{
    //   if(res?.message?.page_content){
    //     this.prepopulateForm(res?.message?.page_content[2])
    //   }
    // })
  }

  prepopulateFormValues(){
    // this.subjectSelected = localStorage.getItem('subject')
    // this.courseSelected = localStorage.getItem('course')
    // this.intakeSelected = localStorage.getItem('intake')
    // this.campusSelected = localStorage.getItem('campus')

    this.subjectSelected = ''
    this.courseSelected = ''
    this.intakeSelected =''
    this.campusSelected = ''

    if(localStorage.getItem('subject')){
      this.selectCourseForm.get('subject')?.setValue(localStorage.getItem('subject'))
    }
    if(localStorage.getItem('course')){
      this.selectCourseForm.get('course')?.setValue(localStorage.getItem('course'))
    }
    if(localStorage.getItem('intake')){
      this.selectCourseForm.get('intake')?.setValue(localStorage.getItem('intake'))
    }
    if(localStorage.getItem('campus')){
      this.selectCourseForm.get('campus')?.setValue(localStorage.getItem('campus'))
    }
    // this.formComplete.emit(this.selectCourseForm.valid)
  }

  prepopulateForm(val: any){
    this.selectCourseForm.patchValue(val)
  }
}
