<div class="bg-white-50 h-[64px] flex items-center shadow-custom-sm mb-[2px]"> 
    <div class="flex justify-between items-center w-full py-[14px] px-[40px] h-full">
      <div class="flex items-center gap-[12px]">
        <h1 class="text-2xl font-heading font-farro font-bold">{{campaignLabel}}</h1>
        <button (click)="openModal()">
          <span *ngIf="isEdit" class="text-primary-400 text-x-small font-inter font-semibold hover:underline flex">Edit</span>
        </button>
      </div>
     <div class="flex space-x-4 items-center h-[40px]">
      <button class="w-[24px] h-[24px]">
        <img src="../../../assets/icons/black-question-icon.svg" alt="help" />
      </button>
      <button class="w-[24px] h-[24px]">
        <img src="../../../assets/icons/black-magnifier-icon.svg" alt="search" />
      </button>
      <button class="w-[24px] h-[24px]">
        <img src="../../../assets/icons/black-bell-icon.svg" alt="notification" />
      </button>
      <div class="flex gap-[4px]">
        <div class="flex gap-[4px] items-center cursor-pointer">
          <span class="w-[40px] h-[40px] rounded-full bg-primary-400 text-white-50 font-semibold font-inter uppercase flex  items-center justify-center">MW</span>
          <span class="w-[24px] h-[24px]">
            <img src="../../../assets/icons/black-down-arrow.svg" alt="down" />
          </span>
        </div>
       
        <!-- Modal overlay and content -->
    <div *ngIf="isModalOpen" class="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
      <div class="relative bg-white-50 shadow-lg rounded-lg p-8 z-10">
          <app-create-campaign [modalConfig]="modalConfig" (close)="closeModal($event)" (campaignNameEvent)="UpdateCampaignName($event)"></app-create-campaign>
      </div>
  </div>
        
    </div>
    </div>
</div>