/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { CommonModule } from '@angular/common'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { ApiService } from '../../services/api.service'
import { ActivatedRoute } from '@angular/router'
import { CampaignService } from '../../services/campaign.service'
@Component({
  selector: 'app-goal',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './goal.component.html',
  styleUrl: './goal.component.css'
})
export class GoalComponent implements OnInit{
  goalForm!:FormGroup
  singleIsChecked: boolean = false
  multiIsChecked: boolean = false
  //new code 
  campaignId: string | null = null
  @Output() formComplete = new EventEmitter<{ isValid: boolean, formValue: any }>(); //new

  constructor(private fb:FormBuilder, private apiService:ApiService, private activatedRoute: ActivatedRoute, private campaignService: CampaignService){
    this.goalForm = this.fb.group({
      goal: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    //Inital Values
    this.campaignId = localStorage.getItem('CampaignId') ? localStorage.getItem('CampaignId') : ''

    this.goalForm.valueChanges.subscribe((res:any)=>{
      if(this.goalForm.valid){
        const isValid = this.goalForm.valid;
        const formValue = this.goalForm.value;
        this.formComplete.emit({ isValid, formValue });
      }
    })

    this.apiService.getCampaignDetails({campaignId:this.campaignId}).subscribe((res:any)=>{
      if(res?.message?.page_content){
        this.prepopulateForm(res?.message?.page_content?.goal)
      }
    })
  }
  SingletoggleCheck (){
    this.singleIsChecked = !this.singleIsChecked
  }

  MultitoggleCheck (){
    this.multiIsChecked = !this.multiIsChecked
  }

  prepopulateForm(val:any){
    this.goalForm.patchValue(val)
  }
}
