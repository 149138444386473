import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { API_END_POINTS } from '../constants/end-point';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private router: Router) { 
  }

  createCampaign(payload : any): Observable<any>{
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.PUBLIC_X_API_KEY,
        // "X-Correlation-Id": check with Zafar (what UUID we ahve to pass here)
        // authorization: check with  zafar (what I can use here as token)
        "Source-App": "Propose_Client_Page",
      })
    }
    const endpoint = environment.DOMAIN + API_END_POINTS.CREATE_CAMPAIGN
    return this.http.post<any>(endpoint, payload, options)
      .pipe(map((result) => {
        return result
      }))
    }

    //update campaign
    updateCampaign(payload : any): Observable<any>{
      console.log(payload, "Payload in Update CAll")
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-api-key': environment.PUBLIC_X_API_KEY,
          // "X-Correlation-Id": check with Zafar (what UUID we ahve to pass here)
          // authorization: check with  zafar (what I can use here as token)
          "Source-App": "Propose_Client_Page",
        })
      }
      const endpoint = environment.DOMAIN + API_END_POINTS.GET_CAMPAIGN + '/' + payload?.campaignId
      return this.http.patch<any>(endpoint, payload, options)
        .pipe(map((result) => {
          return result
        }))
      }

      getCampaignDetails(payload : any): Observable<any>{
        let params = new HttpParams()
        const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-api-key': environment.PUBLIC_X_API_KEY,
            // "X-Correlation-Id": check with Zafar (what UUID we ahve to pass here)
            // authorization: check with  zafar (what I can use here as token)
            "Source-App": "Propose_Client_Page",
          }),
          params
        }
        const endpoint = `${environment.DOMAIN}${API_END_POINTS.GET_CAMPAIGN}/${payload.campaignId}`;
        return this.http.get<any>(endpoint, options)
          .pipe(map((result) => {
            return result
          }))
        }
}
