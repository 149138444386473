import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment.prod';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { routes } from './app/app.routes';

//check with hari/bharath
if (environment.production) 
  enableProdMode()


bootstrapApplication(AppComponent, {
  providers:[
    provideHttpClient(withFetch()), // Configure the HttpClient
    provideRouter(routes)
  ]
})
  .catch((err)=> console.error(err));
