<div class="">
    <form [formGroup]="selectCourseForm">
        
    <h2 class="text-xl font-semibold mb-4">Select course(s)</h2>
    <!-- Study Level Dropdown -->
    <div>
      <label for="studyLevel" class="block mb-2 font-medium">Study Level</label>
      <select formControlName="studyLevel" id="studyLevel" class="w-full bg-grey-lighter p-2 mb-4 border rounded cursor-not-allowed">
        <option value="" disabled>Select Study Level</option> 
        <!-- Dynamic options -->
        @for(level of studyLevels; track $index){
            <option [value]="level">{{ level }}</option>
        }
      </select>
    </div>

    <!-- Subject Dropdown -->
    <div>
      <label for="subject" class="block mb-2 font-medium">Subject</label>
      <select formControlName="subject" id="subject" class="w-full p-2 mb-4 border rounded">
        <option value="" disabled>Select Subject</option>
        @for(subject of subjectsList; track subject){
            <option [value]="subject">{{ subject }}</option>
        }
      </select>
    </div>
  
    <!-- Course Dropdown -->
        <div>
            <label for="course" class="block mb-2 font-medium">Course(s)</label>
            <select formControlName="course" id="course" class='w-full p-2 mb-4 border rounded'>
              <option value="" disabled>Select Course</option>
              @for(course of courseList; track $index){
                  <option [value]="course">{{ course }}</option>
              }
            </select>
          </div>
    
    <!-- new code  -->
    <!-- <label for="course" class="block mb-2 font-medium">Actual Course(s)</label>
    <div class="w-full p-2 mb-4 border rounded" >
    <button (click)="toggleCourseDropdown()" type="button">
                <span for="course" class="mb-2 font-normal">Select Course</span>
                <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
            </svg>
    </button>
</div>

        <ul>
            <li class="">
                <input type="checkbox" id="checkbox-item-1" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                <span>Option 1</span>
            </li>
        </ul>
 -->

    <div id="dropdownBgHover" class="z-10 hidden w-full bg-white rounded-lg shadow border border-gray-300">
        <ul class="p-3 space-y-1 text-sm text-gray-700">
            <li>
                <div class="flex items-center p-2 rounded hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500">
                    <input id="checkbox-item-4" type="checkbox" value="" 
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                    <label for="checkbox-item-4" class="ml-2 text-sm font-medium text-gray-900">Option 1</label>
                </div>
            </li>
            <li>
                <div class="flex items-center p-2 rounded hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500">
                    <input checked id="checkbox-item-5" type="checkbox" value="" 
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                    <label for="checkbox-item-5" class="ml-2 text-sm font-medium text-gray-900">Option 2 (checked)</label>
                </div>
            </li>
            <li>
                <div class="flex items-center p-2 rounded hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500">
                    <input id="checkbox-item-6" type="checkbox" value="" 
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                    <label for="checkbox-item-6" class="ml-2 text-sm font-medium text-gray-900">Option 3</label>
                </div>
            </li>
        </ul>
    </div>
    

    <!-- Intake Dropdown -->
    <div>
      <label for="intake" class="block mb-2 font-medium">Intake</label>
      <select formControlName="intake" id="intake" class="w-full p-2 mb-4 border rounded">
        <option value="" disabled>Select Intake</option>
        @for(intake of intakeList; track $index){
            <option [value]="intake">{{ intake }}</option>
        }
      </select>
    </div>

      <!-- Campus Dropdown -->
      <div>
        <label for="campus" class="block mb-2 font-medium">Campus</label>
        <select formControlName="campus" id="campus" class="w-full p-2 mb-4 border rounded">
          <option value="" disabled>Select Campus</option>
          @for(campus of campusList; track $index){
              <option [value]="campus">{{ campus }}</option>
          }
        </select>
      </div>
</form>
</div>