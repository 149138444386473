import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../../services/campaign.service';
import { CommonModule } from '@angular/common';
import { CreateCampaignComponent } from '../../modals/create-campaign/create-campaign.component';
import { ModalConfig } from '../../models/CreateCampaign.models';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, CreateCampaignComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {
  campaignLabel: string | null = '';
  campaignId: string | null ='';
  isModalOpen: boolean = false
  //new code
  isEdit: boolean = false
  modalConfig: ModalConfig = {heading:'Rename your Campaign', desc:'You can change your campaign name anytime', action:'Update'}

  constructor(private campaignService: CampaignService, public activatedRoute: ActivatedRoute){}

  ngOnInit(): void {
    this.campaignService.campaignNameSubject.subscribe((res:any)=>{
      this.campaignLabel = res
      this.isEdit = true
    })
    //Inital Values
    this.campaignId = localStorage.getItem('CampaignId') ? localStorage.getItem('CampaignId') : ''
    // this.campaignLabel = localStorage.getItem('CampaignName') ? localStorage.getItem('CampaignName') : 'Fastlane Propose'
    this.campaignLabel = localStorage.getItem('CampaignName') ? localStorage.getItem('CampaignName') : 'Fastlane Propose'
    this.isEdit = localStorage.getItem('CampaignName') ? true : false
  }

  // Method to open modal
  openModal() {
    this.isModalOpen = true;
  }

  closeModal(val:any) {
    this.isModalOpen = false;
  }

  UpdateCampaignName(val:any){
    console.log("cgyijhbjkm")
    this.isModalOpen = false
    console.log(val, "VAALLLL")
  }

}
