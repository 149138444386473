/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/unbound-method */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { ApiService } from '../../services/api.service';
import { CommonModule } from '@angular/common';
import { CampaignService } from '../../services/campaign.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ModalConfig } from '../../models/CreateCampaign.models';

@Component({
  selector: 'app-create-campaign',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, RouterModule],
  templateUrl: './create-campaign.component.html',
  styleUrl: './create-campaign.component.css'
})
export class CreateCampaignComponent implements OnInit {

  @Output() close = new EventEmitter<boolean>();  // Event to close the modal
  @Output() campaignNameEvent = new EventEmitter<any>(); //Event to pass the Campaign Name
  @Input() modalConfig: ModalConfig = {heading: 'Create a Campaign', desc:'Set up and manage campaigns easily; no technical skills needed.', action:'Save'}

  //Actual Form 
  createCampaignForm: FormGroup
  currentPage: number = 0
  isError: boolean = false
  errorMessage: string = ''
  campaignId: string | null = null //observable | first time created ID

  constructor(private fb: FormBuilder, public apiService: ApiService, public campaignService: CampaignService, public router: Router, public activatedRoute: ActivatedRoute) {
    // Initialize the form with required validators
    this.createCampaignForm = this.fb.group({
      campaignName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(60)]]
    })
  }

  ngOnInit(): void {
    this.createCampaignForm.valueChanges.subscribe((res) => {
      this.checkForErrors(res.campaignName);
    })

    this.campaignId = localStorage.getItem('CampaignId') ? localStorage.getItem('CampaignId') : ''
  }

  checkForErrors(value: string) {
    if (value === '') {
      this.errorMessage = 'Please enter a Campaign name to proceed';
    } else if (value.length > 60) {
      this.errorMessage = 'Campaign name should not exceed above 60 Char';
    } else {
      this.errorMessage = ''; // Clear error if input is valid
    }
  }

  onSubmit() {
    if (this.createCampaignForm.valid) {
      const campaignName = this.createCampaignForm.value.campaignName;
      localStorage.setItem('CampaignName', campaignName)
      this.campaignService.campaignNameSubject.next(campaignName)
      this.campaignNameEvent.emit({ campaignName: campaignName, pageNumber: 1 });

      console.log(this.campaignId, "Before CampaignID")
      if (!this.campaignId) {
        //API call, to save the campaign details
        this.apiService.createCampaign({ campaignName: this.createCampaignForm.value.campaignName, institutionId: 'IID-AU-00574' }).subscribe((res: any) => {
          console.log("CREATE API CALL")
          console.log(res.campaign_id, "Camapaign Id from response") //need to pass this id in the url/router
          localStorage.setItem('CampaignId', res.campaign_id)
          this.campaignId = res.campaign_id
          this.isError = false

          //Navigate to new router with the campaign ID
          this.router.navigate([`campaign/${res.campaign_id}`]);
        }, error => {
          console.error('Error creating campaign', error);
          this.isError = true;
          this.errorMessage = 'Failed to create campaign. Please try again later.';
        })
      }
      else {
        this.apiService.updateCampaign({campaignName:this.createCampaignForm.value.campaignName, campaignId: this.campaignId}).subscribe((res)=>{
          console.log("UPDATE API CALL")
          console.log(res, "RESPONSE in UPDATING")
        }, error =>{
          console.log("Error in update API", error)
          // this.isError = true
          // this.errorMessage = ''
        })
      }
    }
  }

  // Method to close the modal
  onClose() {
    this.close.emit(false);  // Emit close event to parent component
  }
}
