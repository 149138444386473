import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SharedData } from '../models/CreateCampaign.models';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  campaignNameSubject = new BehaviorSubject<string>('')

  private initialData: SharedData = {
    goal : { goal: '' },
    selectCourse: {
      studyLevel: '',
      country: '',
      subject: '',
      course: [{ course: '', intakeYear: '', campus: '' }],
    },
    audience: {},
    proposal: {
      proposalType: '',
      description: '',
      imageOrVideoFile: '',
      proposalValidity: '',
    },
    schedule: { launchDate: '', endDate: '' },
  };
  
  private dataSubject = new BehaviorSubject<SharedData>(this.initialData);
  sharedData$ = this.dataSubject.asObservable();

  updateData(key: keyof SharedData, value: Partial<SharedData[typeof key]>): void {
    const currentData = this.dataSubject.value;
    const updatedData = {
      ...currentData,
      [key]: { ...currentData[key], ...value },
    };
    this.dataSubject.next(updatedData);
  }

  getData(): SharedData {
    return this.dataSubject.value;
  }
}
