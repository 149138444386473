
        <div class="flex flex-col justify-start grow h-full">
          <div class="flex flex-col h-full">
            <div class="grow overflow-hidden gap-[32px] flex flex-col">
              <div class="flex h-full">
                <div class="flex-1 flex-col justify-between h-full flex  bg-neutral-50">
                    <div class=" block flex-col overflow-y-auto p-[32px] gap-[16px]">

                      <!-- Page Components - GOAL| COURSE| AUDIENCE | PROPOSAL | SCHEDULE -->
                      <ng-container [ngSwitch]="currentPagePointer">
 
                        <!-- Goal Component -->
                      <ng-container *ngSwitchCase="1">
                        <div>
                            <app-goal (formComplete)="onFormValidityChange($event)"></app-goal>
                         </div>
                      </ng-container>
                       
                        <!-- Select Course Component -->
                      <ng-container *ngSwitchCase="2">
                        <div>
                            <app-select-course (formComplete)="onFormValidityChange($event)"></app-select-course>
                        </div>
                      </ng-container>
                       
                        <!-- Audience Component -->
                      <!-- <ng-container *ngSwitchCase="3">
                        <div>
                            <app-first-campaign (formComplete)="onFormValidityChange($event)"></app-first-campaign>
                        </div>
                      </ng-container> -->

                       <!-- Proposal Component -->
                       <ng-container *ngSwitchCase="4">
                        <div>
                          <app-proposal (formComplete)="onFormValidityChange($event)"></app-proposal>
                        </div>
                      </ng-container>

                       <!-- Schedule Component -->
                       <ng-container *ngSwitchCase="5">
                        <div>
                          <app-schedule (formComplete)="onFormValidityChange($event)"></app-schedule>
                        </div>
                      </ng-container>

                      </ng-container>
                    </div>
                    <!-- Button Place -->
                    <div class="flex justify-between h-[65px] py-[12px] bg-white-50 px-[32px] border-t border-solid border-neutral-200">
                      <button [disabled]="currentPagePointer === 1" (click)="onPrevClick(currentPagePointer)"
                      class="btn border h-[43px] w-[100px] text-grey-400 bg-grey-100 border-grey-500">
                        Previous
                    </button>
                      <button [disabled]='isNextDisable' (click)="onNextClick(currentPagePointer)" 
                      [ngClass]="{
                        'btn border h-43px w-100px text-grey-400 bg-grey-100 border-grey-500': isNextDisable,
                        'btn border hover:bg-primary-400 hover:text-white-50 h-40px text-primary-400 bg-white-50 border-primary-400': !isNextDisable
                      }">
                        Next
                      </button>
                    </div>
    
                </div>
                  <div class="w-[284px] shadow-lg p-[16px] bg-white-50 flex flex-col justify-between">
                    <app-right-navbar></app-right-navbar>    
                    <app-right-navbar-quicklink></app-right-navbar-quicklink>       
                  </div>
              </div>
            </div>
            <div class="flex-none bg-neutral-50">
                <!-- <app-footer></app-footer> -->
            </div>   
          </div> 
        </div>