<div class="bg-neutral-50">
  <div class="">
    <div class="flex w-full h-screen justify-start">
      <!-- nav bar  -->
      <div class="flex w-[310px] max-w-[310px] bg-purple-300 shadow-lg flex-none">
        <app-nav-bar></app-nav-bar>
      </div>
      <!-- mid content  -->
      <div class="flex flex-col justify-start grow h-full">
        <div class="flex flex-col h-full">
          <div class="flex-none">
            <app-header></app-header>
          </div>
          <div class="grow overflow-hidden gap-[32px] flex flex-col">
            <div class="flex h-full">
              <div class="flex-1 flex-col justify-between h-full flex  bg-neutral-50">
                  <div class=" block flex-col h-full gap-[16px]">
                    <!-- <app-progress-bar></app-progress-bar> -->
                    <!-- <app-no-campaign></app-no-campaign> -->
                    <router-outlet></router-outlet>
                  </div>
              </div>
              <!-- <div class="w-[284px] shadow-lg p-[16px] bg-white-50 flex flex-col justify-between">
                <app-right-navbar></app-right-navbar>    
                <app-right-navbar-quicklink></app-right-navbar-quicklink>       
              </div> -->
            </div>
          </div>
          <div class="flex-none bg-neutral-50">
              <!-- <app-footer></app-footer> -->
          </div>   
        </div> 
      </div>
    </div>
  </div>
</div>