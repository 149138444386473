import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormsModule, ReactiveFormsModule, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { CampaignService } from '../../services/campaign.service';

@Component({
  selector: 'app-schedule',
  standalone: true,
  imports: [FormsModule,ReactiveFormsModule,CommonModule],
  templateUrl: './schedule.component.html',
  styleUrl: './schedule.component.css'
})
export class ScheduleComponent  implements OnInit{
  scheduleForm!:FormGroup
  data: any;
  campaignId: string | null = localStorage.getItem('CampaignId') ? localStorage.getItem('CampaignId') : ''
  @Output() formComplete = new EventEmitter<{ isValid: boolean, formValue: any }>(); //new

  constructor(
    private readonly fb: FormBuilder, 
    private readonly apiService:ApiService,
    private readonly campaignService:CampaignService
  ){}  currentDate: any;

  ngOnInit(){
    this.scheduleForm = this.fb.group({
      launchDate: [new Date().toISOString().substring(0, 10),Validators.required],
      // launchTime: [''],
      endDate: [new Date().toISOString().substring(0, 10),Validators.required],
      // endTime: [''],
    })
        // Get the current date in yyyy-MM-dd format
        const today = new Date();
        this.currentDate = today.toISOString().split('T')[0];

        this.apiService.getCampaignDetails({campaignId: this.campaignId}).subscribe((res:any)=>{
          this.data = res?.message
          this.prePopulateData()
        })

        this.scheduleForm.valueChanges.subscribe((res: any) => {
          console.log(res)
          if (this.scheduleForm.valid) {
            const isValid = this.scheduleForm.valid; //valid or not
            const formValue = this.scheduleForm.value;// form value
            console.log(isValid, "isValid")
            console.log(formValue, "FormValue")
            this.formComplete.emit({ isValid, formValue });
          }
        })
  }

  prePopulateData(){
    this.scheduleForm.patchValue({
      launchDate : this.data?.page_content?.schedule?.launchDate,
      endDate : this.data?.page_content?.schedule?.endDate,
    })
  }

  submit(){
    // const { 
    //   '1': goal, 
    //   '2': course, 
    //   '3': audience, 
    //   '4': proposal,
    //   '5': schedule
    // } = this.campaignService.getData();
    const body = {
      // "pageContent": {
      //     "1": goal,
      //     "2": course,
      //     "3": audience,
      //     "4": proposal,
      //     "5": this.scheduleForm.value,
      // }
  }
  console.log(body)

    this.apiService.updateCampaign({campaignId: this.campaignId,body}).subscribe((res:any)=>{
      console.log(res,"response for getCampaign")
    })
  }
}
