import { Component, OnInit } from '@angular/core'
import { ProgressBarComponent } from '../../common/progress-bar/progress-bar.component'
import { CreateCampaignComponent } from '../../modals/create-campaign/create-campaign.component'
import { CommonModule } from '@angular/common'
import { GoalComponent } from '../goal/goal.component'
import { SelectCourseComponent } from '../select-course/select-course.component'
import { FirstCampaignComponent } from '../first-campaign/first-campaign.component'
import { ModalComponentComponent } from '../../modals/modal-component/modal-component.component'
import { ActivatedRoute } from '@angular/router'
import { CampaignFormComponent } from '../campaign-form/campaign-form.component'

@Component({
  selector: 'app-no-campaign',
  standalone: true,
  imports: [CreateCampaignComponent, ProgressBarComponent, GoalComponent, CommonModule, SelectCourseComponent, FirstCampaignComponent, ModalComponentComponent, CampaignFormComponent],
  templateUrl: './no-campaign.component.html',
  styleUrl: './no-campaign.component.css'
})
export class NoCampaignComponent implements OnInit{
  // Variable to track modal visibility
  isModalOpen = false
  campaignCreated: boolean = false
  campaignName: string = ''
  currentPagePointer: number = 0
  // new
  campaignId: string | null = null

  constructor (private activatedRoute: ActivatedRoute){
    this.activatedRoute?.paramMap?.subscribe(params => {
      this.campaignId = params.get('id')
      console.log('Campaign ID:', this.campaignId)
    })
  }

  ngOnInit (): void {
  }

  // Method to open modal
  openModal () {
    this.isModalOpen = true
  }

  // Method to close modal
  closeModal (val: any) {
    this.isModalOpen = false
    this.campaignName = ''
    this.campaignCreated = val
  }

  CampaignCreationStarted(val:any){
    this.isModalOpen = false;
    this.campaignCreated = val.campaignName ? true : false
    this.campaignName = val.campaignName
    this.currentPagePointer = val.pageNumber
  }
}
