import { Routes } from '@angular/router';
import { NoCampaignComponent } from './components/no-campaign/no-campaign.component';
import { CreateCampaignComponent } from './modals/create-campaign/create-campaign.component';
import { GoalComponent } from './components/goal/goal.component';
import { CampaignFormComponent } from './components/campaign-form/campaign-form.component';

export const routes: Routes = [
    {
    path:'create-campaign',
    component: NoCampaignComponent
    },
    {
        path: '',
        redirectTo: '/create-campaign', 
        pathMatch:'full',
    },
    {
        path:'campaign/:id',
        component:CampaignFormComponent,
        pathMatch:'full'
    }
];
