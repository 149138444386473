<div class="flex flex-col gap-[16px] bg-white-50 p-[24px] shadow-md">
    <div class="flex flex-col gap-[4px]">
        <h6 class="font-farro font-bold text-para-lg text-grey-300">Schedule your campaign</h6>
        <p class="font-inter font-normal text-small text-grey-300">Set the dates and times for when you'd like your proposal to be sent to your selected audience
            <span>Times are in IST (India)</span>
        </p>
    </div>
    <form [formGroup]="scheduleForm" class="flex gap-[16px] w-full">
        <div class="flex gap-[16px] flex-col w-full">
            <p class="font-semibold font-inter text-small text-neutral-600">Date and time to launch</p>
            <div class="inline-flex w-full gap-[16px]">
                <input formControlName="launchDate" class="py-[10px] px-[12px] text-small font-inter font-normal block  border border-neutral-400 rounded-md w-[calc(100%-116px)]" type="date" />
                <!-- <select
                    id="country"
                    name="country"
                    autoComplete="country-code"
                    class="py-[10px] pl-[12px] text-small font-inter font-normal flex w-[100px] border border-neutral-400 rounded-md">
                    <option>16:30</option>
                    <option>17:00</option>
                    <option>17:30</option>
                </select> -->
            </div>
        </div>
        <div class="flex gap-[16px] flex-col w-full">
            <p class="font-semibold font-inter text-small text-neutral-600">Date and time to launch</p>
            <div class="inline-flex w-full gap-[16px]">
                <input  formControlName="endDate" class="py-[10px] px-[12px] text-small font-inter font-normal block  border border-neutral-400 rounded-md w-[calc(100%-116px)]" type="date" />
                <!-- <select
                    id="country"
                    name="country"
                    autoComplete="country-code"
                    class="py-[10px] pl-[12px] text-small font-inter font-normal flex w-[100px] border border-neutral-400 rounded-md">
                    <option>16:30</option>
                    <option>17:00</option>
                    <option>17:30</option>
                </select> -->
            </div>
        </div>
    </form>
</div>