export const CONSTANTS = {
 
    DEV: {
        //Domain and key
        DOMAIN: 'https://api.propose.dev.idp.com/client',
        X_API_KEY: 'EAPkKQBpUTek6UriIKen7Xza18RAUlR9yiysUUs6',
    },
 
    STG: {
        //Domain and key
        DOMAIN: '',
        X_API_KEY: '',
    },
 
    UAT: {
        //Domain and key
        DOMAIN: '',
        X_API_KEY: '',
    },
 
    PROD: {
        //Domain and key
        DOMAIN: '',
        X_API_KEY: '',
    }
}