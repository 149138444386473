import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { Proposal } from '../../models/CreateCampaign.models';
import { CampaignService } from '../../services/campaign.service';

@Component({
  selector: 'app-proposal',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './proposal.component.html',
  styleUrl: './proposal.component.css'
})
export class ProposalComponent implements OnInit {
  progress: number = 50
  progressStatus: string = 'success'
  proposalForm!: FormGroup<Proposal>;
  selectedFile: any;
  selectedFileSize: any;
  currentDate: any;
  data: any;
  campaignId: string | null = localStorage.getItem('CampaignId') ? localStorage.getItem('CampaignId') : ''
  @Output() formComplete = new EventEmitter<{ isValid: boolean, formValue: any }>(); //new

  constructor(
    private readonly fb: FormBuilder,
    private readonly apiService: ApiService,
    private readonly campaignService: CampaignService
  ) { }
  ngOnInit() {
    this.proposalForm = this.fb.nonNullable.group({
      proposalType: ['', Validators.required],
      description: ['', Validators.maxLength(10)],
      imageOrVideoFile: [''],
      proposalValidity: [new Date().toISOString().substring(0, 10), Validators.required],
    })
    // Get the current date in yyyy-MM-dd format
    const today = new Date();
    this.currentDate = today.toISOString().split('T')[0];


    this.proposalForm.valueChanges.subscribe((res: any) => {
      if (this.proposalForm.valid) {
        const isValid = this.proposalForm.valid; //valid or not
        const formValue = this.proposalForm.value;// form value

        this.formComplete.emit({ isValid, formValue });
      }
    })

    this.apiService.getCampaignDetails({ campaignId: this.campaignId }).subscribe((res: any) => {
      this.data = res?.message
      console.log(this.data?.page_content?.proposal, "Proposal")
      this.prePopulateData()
    })
  }

  prePopulateData() {
    this.proposalForm.patchValue({
      proposalType: this.data?.page_content?.proposal?.proposalType,
      description: this.data.page_content?.proposal?.description,
      proposalValidity: this.data.page_content?.proposal?.proposalValidity
    })
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input?.files && input.files.length > 0) {
      const file = input.files[0];
      this.selectedFile = file;
      this.selectedFileSize = this.formatFileSize(file.size)
      // this.proposalForm.patchValue({ file }); // Update FormControl with the selected file
      console.log('Selected file:', file);
    }
  }
  // Remove the selected file
  removeFile() {
    // this.proposalForm.patchValue({ imageOrVideoFile: null });
    this.selectedFile = null;
    console.log('File removed.');
  }

  onImageKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      // Trigger the click action on "Enter" or "Space"
      this.removeFile();
    }
  }

  // Convert file size to KB or MB
  formatFileSize(sizeInBytes: number): string {
    if (sizeInBytes < 1024) {
      return `${sizeInBytes} bytes`;
    } else if (sizeInBytes < 1024 * 1024) {
      return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    } else {
      return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
    }
  }
}
